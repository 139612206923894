<template>
  <div id="histogram" :style="{width: '100%', height: '280px'}"></div>
</template>

<script>
export default {
  props: ["shopCharge"],
  mounted() {
    this.getEcharts();
  },
  watch: {
    shopCharge() {
      this.getEcharts();
    }
  },
  methods: {
    getEcharts() {
      let shopCharge = [];
      this.shopCharge.map(item => {});
      let name = this.shopCharge.map(item => {
        return item.name.slice(0, 10);
      });
      let value = this.shopCharge.map(item => {
        return item.value / 100;
      });
      const echarts = require("echarts");
      const historgrams = echarts.init(document.querySelector("#histogram"));
      let option = {
        title: {
          text: "今日收费商户排行",
          textStyle: {
            fontSize: 14
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          left: "30%",
          right: "10%"
        },
        xAxis: [
          {
            type: "value",
            axisTick: {
              //y轴刻度线
              show: false
            },
            splitLine: { show: false }, //去除网格线
            axisLabel: {
              show: false
            },
            axisLine: {
              show: false
            }
          }
        ],
        yAxis: {
          type: "category",
          splitLine: { show: false }, //去除网格线
          axisLabel: {
            color: "#2d68a4", //刻度线标签颜色
            fontWeight: 600,
            fontSize: 12
          },
          axisTick: {
            //y轴刻度线
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070"
            }
          },
          data: name
        },
        series: [
          {
            name: "总金额（元）",
            type: "bar",
            barWidth: "15",
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#5FA1DC",
                fontSize: 12
              }
            },
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    fontSize: 14
                  }
                }
              }
            },
            itemStyle: {
              // 圆角设置
              emphasis: {
                barBorderRadius: 10
              },
              normal: {
                barBorderRadius: 10,
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "#EAEAEA" },
                  { offset: 1, color: "#5FA1DC" }
                ])
              }
            },
            data: value
          }
        ]
      };
      historgrams.setOption(option);
      window.addEventListener("resize", function() {
        historgrams.resize();
      });
    }
  }
};
</script>

<style>
</style>