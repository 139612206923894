<template>
  <div class="seamless-warp">
    <vue-seamless-scroll
      :data="listData"
      :class-option="optionSetting"
      :style="{ overflow: 'hidden',height:'calc(100% - 0px)' }"
    >
      <ul class="item">
        <li v-for="(item,index) in listData" :key="index"  class="list">
          <div>{{item.create_time}}</div>
          <div>{{item.shop_name}}</div>
          <div v-if="sign">{{item.total}}</div>
          <div v-else>{{item.project_name}}</div>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  props:['ParkData','sign'],
  data() {
    return {
      listData: []
    };
  },
  watch:{
    ParkData(){
      this.listData = this.ParkData;
    }
  },
  computed: {
    optionSetting() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1 // 0向下 1向上 2向左 3向右
      };
    },
  },
  created(){
    this.listData = this.ParkData;
  },
  mounted(){
    this.setBgiColor();
  },
  methods:{
    // li标签列表中偶数行的bgc设置
    setBgiColor(){
      let list = document.querySelectorAll('.list');
      for(var i = 0; i < list.length; i++) {
        if(i%2===0){
          list[i].style.backgroundColor = '#F8F8F8';
        }
      }
      
    }
  }
};
</script>

<style>
.seamless-warp {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px 8px;
  height: 230px;
}
.item {
  margin: 0;
  padding: 0;
}
.item>li{
  display:flex;
  margin: 0 !important;
  justify-content: space-between;
}
.item>li>div{
  width: 20%;
  font-size: 10px;
  text-align: center;
  padding: 7px 0;
}
.item>li>div:first-child{
  width: 40%;
}
.item>li>div:nth-child(2){
   width: 25%;
}
.seamless-warp>div>div{
  width: 100%;
}
</style>