<template>
  <div id="ChargesPic" :style="{width: '100%', height: '260px'}"></div>
</template>

<script>
export default {
  props: ["lastTenDaysCharge"],
  data() {
    return {
      a: [
        { charge: 0, days: "" },
        { charge: 0, days: "" },
        { charge: 0, days: "" },
        { charge: 0, days: "" },
        { charge: 0, days: "" },
        { charge: 0, days: "" },
        { charge: 0, days: "" }
      ]
    };
  },
  mounted() {
    this.getEcharts();
  },
  watch: {
    lastTenDaysCharge() {
      // this.getEcharts();
    }
  },
  methods: {
    getEcharts() {
      // Z轴数据下标与Y轴数据下标对应
      let dataX = this.lastTenDaysCharge.map(item => {
        return item.days.slice(5, 10);
      });

      // Y轴数据
      let dataY = this.lastTenDaysCharge.map(item => {
        return item.charge / 100;
      });
      const echarts = require("echarts");
      const Charge = echarts.init(document.querySelector("#ChargesPic"));
      let option = {
        title: {
          text: "近十日收费记录",
          textStyle: {
            fontSize: 14
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "总收入：{c} (元)"
        },
        grid: {
          x: "10%", //相当于距离左边效果:padding-left
          y: "20%", //相当于距离上边效果:padding-top
          bottom: "15%"
          //         containLabel: true
        },
        toolbox: {
          //保存为图片
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2
            }
          }
        },
        xAxis: {
          data: dataX,
          offset: 12,
          //    去掉x轴坐标线
          // axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: "#2d68a4", //刻度线标签颜色
            fontWeight: 700,
            fontSize: 15
          },
            axisLine: {
              show: false,
            }
        },
        yAxis: {
          // axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: "#2d68a4", //刻度线标签颜色
            fontWeight: 700,
            fontSize: 15
          },
           axisLine: {
              show: false,
            },
          splitLine: {
            //网格线
            lineStyle: {
              type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
              color: "#D2B0F6"
            }
          }
        },
        series: [
          {
            barWidth: "60%", //图形宽度设置
            name: "直接访问",
            type: "bar",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#63A3DF",
                fontSize: 12
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#63A3DF" }, //柱图渐变色
                  { offset: 1, color: "#EAEAEA" } //柱图渐变色
                ])
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#EAEAEA" }, //柱图高亮渐变色
                  { offset: 1, color: "#63A3DF" } //柱图高亮渐变色
                ])
              }
            },
            data:dataY
          }
        ]
      };
      Charge.setOption(option);
      window.addEventListener("resize", function() {
        Charge.resize(); 
      });
    }
  }
};
</script>

<style>
</style>