<template>
  <div>
    <div :id="id" :style="{width: '95%', height: '250px'}"></div>
  </div>
</template>
<script>
export default {
  props: ["toDayCountRatio","id","company"],
  data() {
    return {
      newtotalIncomPie: [
        { value: 0, name: "微信" },
        { value: 0, name: "支付宝" },
        { value: 0, name: "云纵" }
      ],
      arr: [], //渠道
      total:0,
    };
  },
  watch: {
    toDayCountRatio() {
      this.newtotalIncomPie = this.toDayCountRatio;
      this.total=0;
      this.toDayCountRatio.forEach(v=>{
        this.total+=v.value;
      })
      this.gethome();
    }
  },
  methods: {
    //环形饼状图
    gethome() {
      var index = 0;
      var echarts = require("echarts");
      var myPiechart = echarts.init(document.getElementById(this.id));
      let option = {
           title: {
          text:'总计',
          subtext:this.total ? this.total.toFixed(2) : "0",
          textStyle: {
            color: "black",
            fontSize: 15
          },
          subtextStyle: {
            fontSize: 15,
            color: ["#74D331"]
          },
          x: "center",
          y: "40%"
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}"
        },
        legend: {
          //图例
          orient: "horizontal为水平", //图例的布局，竖直    horizontal为水平
          x: "center", //图例位置
          y: "92%",
          itemWidth: 15,
          itemHeight: 10,
          textStyle: {
            //图例文字的样式
            fontSize: 12 //文字大小
          },
          //百分比计算
          formatter: function(name) {
            const dataList = option.series[0].data;
            let totalnum = 0; //总金额
            let percentage = 0;
            let value = "";
            dataList.forEach(item => {
              totalnum += item.value;
            });
            dataList.map(item => {
              if (item.name === name) {
                percentage = item.value / totalnum;
                if (percentage) {
                  value = `${name + " " + (percentage * 100).toFixed(2) + "%"}`;
                } else {
                  value = `${name + " " + "0.00" + "%"}`;
                }
              }
            });
            return value;
          }
        },
        series: [
          {
            label: {
              normal: {
                show: true,
                formatter: "{b}: {c}" + this.company,
                lineStyle: {
                  color: "yellow",
                  width: 2
                }
              }
            },
            center:'center', //图形位置
            name: "访问来源",
            type: "pie", //环形图的type和饼图相同
            radius: ["28%", "55%"], //饼图的半径，第一个为内半径，第二个为外半径
            avoidLabelOverlap: false,
            color: ["#00AEF5", "#74D331", "#FB6E51"],
            data: this.newtotalIncomPie
          }
        ]
      };
      myPiechart.setOption(option, true);
      window.addEventListener("resize", function() {
        myPiechart.resize();
      });
      myPiechart.on("mouseover", function(e) {
        //鼠标移到元素时高亮
        if (e.dataIndex != index) {
          myPiechart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: index
          });
        }
      });
    }
  }
};
</script>

<style>
</style>